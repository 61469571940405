import { createIcon } from "@chakra-ui/react";

export const LogoIcon = createIcon({
  displayName: "LogoIcon",
  viewBox: "0 0 138.62 190.68",
  // path can also be an array of elements, if you have multiple paths, lines, shapes, etc.
  path: (
    <path d="m116.88,166.06c10.24-18.16,7.45-43.76-6.49-57.75l-7.56-7.71,29.23-29.36L60.91,0,20.01,40.85c-8.09,8.09-12.55,18.85-12.55,30.3s4.46,22.21,12.55,30.3l9.84,9.84L0,142.26l45.7,48.32h15.18l-45.68-48.3,21.75-22.61,23.98,23.35,34.3-34.61,7.46,7.35c6.48,6.48,10.05,15.07,10.05,24.17,0,10.82-2.43,19.15-7.66,26.19l-23.76,24.56h15.16l13.82-14.46,13.43,14.46h14.89l-21.74-24.62Zm-21.73-73.33l-1.41-1.49-22.58-22.62-33.71,34.79-9.7-9.74c-6.04-6.04-9.37-14.05-9.37-22.57s3.33-16.53,9.37-22.56L60.96,15.33l55.81,55.77-21.62,21.63Zm-34.2,34.24l-15.81-15.81,26.12-27.17,16.43,16.24-26.74,26.74Z" />
  ),
});
